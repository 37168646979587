const lang = {
  common: {
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    submit: 'Submit',
    confirm: 'Confirm',
    done: 'Done',
    buy: 'Buy',
    close: 'Close',
    today: 'Today',
    day: 'Day',
    days: 'days',
    signup : "Sign up",
    register: 'Register',
    login: "Login",
    logout: 'Logout',
    noData: 'No Data',
    noMore: 'No More',
    loading: 'Loading',
    downloading: 'Downloading',
    send: 'Send',
    sms: 'Send OTP',
    smsColdDown: 'Reacquired after',
    second: ' S',
    modify: 'Modify',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    record: 'Record',
    records: 'Records',
    link: "Link",
    bind: 'Bind',
    details: 'Details',
    ok: "Ok",
    rules: 'Rules',
    diamonds: 'Diamonds',
    exchange: 'Exchange',
    on: 'On',
    off: 'Off',
    copy: 'Copy',
    reset: 'Reset',
    follow: 'Follow',
    getTask: 'Get Task',
    getReward: 'Get Reward',
    goTo: 'Go To',
    go: 'Go',
    save: 'Save',
    deleteAll: 'Delete all',
    claim: 'Claim',
    claimed: 'Claimed'
  },
  placeholder: {
    default: 'Please Enter',
    email: "Enter valid email",
    pwd: "Please enter a password",
    minPrice: 'MIN Amount',
    maxPrice: 'MAX Amount',
    referralCode: 'Enter the superior User ID',
    amount: "Please enter the amount! (P[[min]]-P[[max]])",
    chooseBank: 'Choose your Bank',
    chooseCoupon: 'Choose your Coupon',
    registerPhone: 'Enter 10 digit mobile phone number',
    mobilePhone: 'Enter 10-11 digit mobile phone number',
    password: 'Enter 6-12 digit password',
    otp: 'Please enter OTP',
  },
  tabbar: {
    share: 'Share',
    livechat: 'Livechat',
    record: 'Records',
    home: 'Home',
    event: 'Events',
    vip: 'Vip',
    setting: 'Settings',
  },
  actionBtn: {
    daily: 'Daily Log In Bonus',
    invite: 'Invite Friends to Earn',
    task: 'Task',
    inbox: 'Inbox',
    follow: 'Follow Rewards',
    redeem: 'Redeem',
    securityCenter: 'Security Center',
    bind: 'Bind',
    download: 'Download\nApp',
    getReward: 'Get Rewards',
  },
  label: {
    account: 'Account',
    email: "Email address",
    mail: "Email address",
    phone: "Mobile Phone",
    name: "Name",
    nickname: "Nickname",
    accountPwd: "Account Password",
    pwd: "Password",
    createPwd: "Create Password",
    confirmPwd: "Confirm Password",
    oldPwd: 'Old Password',
    newPwd: 'New Password',
    invitationCode: "Invitation Code",
    referralCode: "Referral Code",
    captcha: "CAPTCHA (I'm not a robot)",
    verifyCode: 'Verify Code',
    orderNum: 'Order Number',
    amount: 'Amount',
    time: 'Time',
    dateAndTime: 'Date & Time',
    status: 'Status',
    trasanctionType: 'Transaction Type',
    trasanctionAmount: 'Transaction Amount',
    currentBalance: 'Current Balance',
    availableBalance: 'Available Balance',
    diamond: 'Diamond',
    bonus: 'Bonus',
    userId: 'User ID',
    sn: 'SN',
    note: 'Note',
    vipLevel: 'Vip Level',
    pointsBonus: 'Bonus Points Rwwards',
    betBonus: 'Bet Bonus Commossion',
    slotBonus: 'Slot Bonus Commossion',
    liveBonus: 'Live Game Bonus Commossion',
    bank: 'Bank',
    reason: 'Reason',
    superior: 'Superior',
    coupon: 'Coupon',
    registerTime: 'Register time',
    bindMobile: 'Bind mobile number',
    reward: 'Reward',
  },
  msg: {
    notice : 'Notice',
    logout: 'Login information is not deleted when logging out, this account can still be used the next time you log in.',
    invalidRechargeNumber: 'Recharge number formatted incorrectly',
    productLock: 'To unlock this option, you must first level up.',
    noFilled :'No information been filled before.',
    outOfStock: 'Out of Stock',
    flashSoldOut: 'This flash item is sold out.\nTry agin next time.',
    insufficientEgg: 'Insufficient Egg balance',
    buyProduct: 'Are you sure to buy this order?',
    orderSuccess: 'Your Order Has Been Successfully Placed!',
    pwdNotEqual: 'Two password entries are inconsistent!',
    claimed: "You've Claimed",
    notSigninDate: 'Sign-in date not arrived',
    deleteAllReadMessages: 'Are you sure to delete all read messages?',
    versionUpdate: 'Your app is not the latest version.\nUpdate now?',
    openBrowser: 'Open the page in new tab',
    openDeposit: 'Success! please go to the deposit page (Recommend to use portrait screen).',
  },
  notify: {
    operateSuccess: 'Processed Successfully',
    copySuccess: 'Copy Successfully',
    sentSuccess: 'Sent Successfully',
    registerSuccess: 'Registered Successfully',
    shareSuccess: 'Share url copied!',
  },
  forgetPassword: {
    title: 'Password Retrieval',
    forget: 'Forget Password?',
  },
  welcome: {
    joinRewrad: {
      content1: 'Join us and win',
      content2: 'for free',
      content3: 'more bonuses on waiting for you'
    },
    registerRewrad: 'Get',
  },
  user: {
    accountLogin: 'Account Login',
    forgetPassword: 'Forgot your password?',
    setPassword: 'Set a new password',
    withdrawPassword: 'Withdraw Password',
    profile: 'Personal Information',
    avatar: 'Avatar',
    modification: 'Modification',
    bindSuperior: 'Bind Superior',
    bindTip: 'Note: Only can bind one time',
    bindAccount: 'Bind Account',
    guestLogin: 'Guest Login',
    securityCenter: {
      personalInformation: {
        title: 'Personal Information',
        extra: 'Complete personal information'
      },
      cryptoWallet: {
        title: 'Bind Crypto Wallet',
        extra: 'Please use actual cryto Wallet'
      },
      eWallet: {
        title: 'Bind E-Wallet',
        extra: 'Bind E-wallet for withdraw'
      },
      loginPwd: {
        title: 'Change Login Password',
        extra: 'Recommended letter and number combination'
      },
      transactionPwd: {
        title: 'Transaction Password',
        extra: 'See a fund password to improve the secutry of the fund operations'
      },
    }
  },
  finance: {
    type: {
      gcash: 'GCash',
      maya: 'PayMaya',
      bank: 'Bank',
      usdt: 'USDT',
      singUpBonus: 'Sign Up Bonus',
      exchange: 'Exchange',
    },
    extra: {
      qr: 'QR',
      dr: 'DR',
      nq: 'NQ',
    },
    accountInformation: 'Account Information',
    chooseBank: 'Choose Bank',
    chooseCoupon: 'Choose Coupon',
    accountTip: 'Please make sure your account number is correct , we do not take any responsibility caused by incorrect input',
    deposit: {
      recordTitle: 'Top up record',
      status: {
        0: 'Pending',
        1: 'Success',
        2: 'Failed',
        3: 'Timeout',
      }
    },
    withdraw: {
      withrawalHistory: 'Withdrawal history',
      amountToWithdraw: 'Amount to withdraw',
      amountCanbeWithdraw: 'Amount that can be withdrawn',
      turnover: 'Turnover',
      rule: {
        content1: '1. Minimum withdrawal amount is 100',
        content2: '2. The higher the VIP Level, the larger the amount that can be withdrawn. And the higher the withdrawal limit the higher the withdrawal limit.',
      },
      status: {
        0: 'Pending',
        1: 'Reviewed',
        2: 'Success',
        3: 'Failed',
        4: 'Rejected',
        5: 'Exception',
        6: 'Processing'
      }
    },
  },
  livechat: {
    title: 'Live Chat',
    service: 'Service',
    customerService: 'Customer Service',
    onlineService: 'Online Service',
    info: 'Dear Players,\n\nIf you encounter game problems, or provide valuable game suggestions, you are welcome to contact customer service, we will be happy to serve you 24 hours a day.\n\nThank you for your support to the game, Have a nice Day',
  },
  record: {
    type: {
      depositAndWithdraw: 'Withdrawal & Deposit',
      others: 'Others',
    },
  },
  event: {
    title: 'Announcement'
  },
  task: {
    totalActiveness: 'Total Activeness',
  },
  signUp: {
    title1: 'Sign-up Bonus',
    title2: 'Choose One of three',
    subTitle: 'Only one item can be purchased',
    totalRewrad: 'Total Rewrad',
    firstDepoistReward: 'First Deposit Reward',
    dailyRewrad: 'Daily (Turnover 10 times)',
    dailyLoginBonus: 'Daily Login Bonus',
    free: 'Free',
    rewards: 'Rewards',
    clickToClaim: 'Click to claim',
    freeRewardInfo: 'Claim your free daily login bonus for [[day]] days!',
    levelRewardInfo: {
      init: 'Deposit a total of [[amount]]php to unlock extra [[day]] days bonus',
      common: 'Deposit a total of [[amount]]php to unlock the next level [[day]] days login bonus',
      max: 'Congratulations! You have reached [[level]]. Enjoy the maximum bonus for [[day]] days!',
      tip: 'Note: The effect does not stack. The highest tier login bonus will take effect.'
    },
    max: 'Max',
  },
  luckyWheel: {
    withdrawalAmount: 'Withdrawal bonus amount',
    expires: 'ExpiresAfter',
    missTip1: 'Missing only',
    missTip2: 'peso.',
    luckyPlayer: 'Lucky Player',
    start: 'Spin',
    // noticeSpinTip: '[[interval]] Hours, Get [[spin]] free spin chance',
    noticeSpinTip: {
      tip1: '[[interval]] Hours,',
      tip2: 'Get [[spin]] free spin',
      tip3: 'Chance',
    },
    congratulations: 'Congratulations! you get bonus',
    congratulationTip: {
      tip1: 'when you accumulate',
      tip2: 'you can withdraw immediately'
    },
    notice: 'Invite friends to earn free spin!',
    outOf: 'out of',
    cloverTips: 'Collect [[count]] Fragments to activate withdrawal',
    invite: {
      title: 'Tips',
      inviteFriend: 'Invite [[num]] friend',
      inviteFriendTip: 'Invite new friends to join your number to get a chance to spin for free [[num]] times.',
    },
    reward: {
      amount: {
        title: 'The Luckiest Person',
        content: 'Lucky star! Good luck again [[amount]]',
      },
      points: {
        title: 'The Luckiest Person',
        content: 'Lucky star! Good luck again [[amount]]',
      },
      dual: {
        title: 'The winnings will be doubled on the next spin.',
        content: 'Double cards will double the bonus on the next spin.',
      },
      doubleBonus: {
        title: 'Double Bonus',
        content: 'Lucky star! Good luck again [[amount]]',
      },
      lucky: {
        title: 'Congratulations! Get lucky fragments',
        content: 'If you have enough lucky fragments You can withdraw directly!',
      },
      respin: {
        title: "Congratulations! Get respin.",
        content: 'You can spin again',
      },
      withdraw: {
        title: 'Congratulations! Get Withdraw now.',
        content: 'You can withdraw directly!',
      },
    }
  },
  inbox: {
    title: 'Message',
  },
  vip: {
    vip: 'Vip',
    vipBenefits: 'VIP benefits',
    bonusDiamonds: 'Bonus diamonds',
    lossRebate: 'Loss Rebate',
    slotMachineRebates: 'Slot Machine rebates',
    liveGameRebates: 'Live game rebates',
    rechargeRequirements: 'Recharge Requirements',
    betRequirements: 'Bet Requirements',
    topUpRequirements: 'Top up another P[[amount]] to become VIP[[vip]]',
    topUp: 'Top Up',
    rule: {
      title: 'Upgrade to VIP to get higher withdrawal limits.',
      subTitle1: 'Vip upgrade conditions:',
      content1: 'Must meet the top-up conditions and betting conditions.',
      subTitle2: 'Daily withdrawal limit:',
      content2: 'Each VIP level has different daily withdrawals limits. when the daily withdrawal limit reaches the maximum limit will not be able to withdraw again please withdraw again the next day.',
      subTitle3: 'Withdrawal limits:',
      content3: 'Each VIP level has different withdrawals limits. When the withdrawal limit reaches the maximum limit will not be able to withdraw again please withdraw the next day.',
    },
  },
  socialMedia: {
    followRewards: 'Follow Rewards',
  },
  setting: {
    sound: 'Sound',
    music: ' Music',
  }
}

export default lang;
